@font-face {
  font-family: "Tenor Sans";
  src: url(../css/fonts/tenorsans-regular.ttf);
}

@font-face {
  font-family: "Twentieth Century";
  src: url(../css/fonts/twentieth-century.ttf);
}
@font-face {
  font-family: "Twentieth Century Bold";
  src: url(../css/fonts/twentieth-century-bold.ttf);
  font-weight: 500;
}

:root {
  --brand-bg: #F4F1EA;
  --brand-color: #3E2215;
  --heading-fonts: Tenor Sans;
  --body-fonts: Twentieth Century;
}

body {
  font-family: var(--body-fonts);
}

.app-wrapper {
  background-color: var(--brand-bg);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-fonts);
}

.h2 {
  font-size: 25px;
  line-height: 40px;
}

.form-control {
  border: 1px solid var(--brand-color);
  background-color: transparent;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  color: var(--brand-color);
  display: inline-block;
  margin-bottom: 5px;
}

.form-control:focus {
  border-color: var(--brand-color);
  box-shadow: 0 0 0 .2rem #42211636;
  background-color: transparent;
}

.form-group-icon {
  position: relative;
}

.form-group-icon i {
  position: absolute;
  top: 42px;
  left: 15px;
  padding-right: 10px;
  display: inline-block;
  border-right: 1px solid var(--brand-color);
  margin-right: 10px;
  color: var(--brand-color);
  z-index: 1;
}

.form-group-icon .form-control {
  padding: 8px 50px;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .custom-control-label {
  position: relative;
}

.custom-checkbox .custom-control-label::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--brand-color);
  border-radius: 2px;
}

.custom-checkbox .custom-control-label {
  padding-left: 25px;
  font-size: 14px;
  cursor: pointer;

}

.custom-checkbox .custom-control-input:checked~ :before {}

.theme-btn {
  padding: 8px 40px;
  border-radius: 5px;
  background-color: var(--brand-color);
  color: #fff;
  font-weight: 600;
}

.text-uppercase {
  text-transform: uppercase;
}

a {
  color: var(--brand-color);
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
  outline: 0;
}

.align-middle {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
}

.theme-btn:hover,
.theme-btn:focus,
.theme-btn:focus-visible {
  color: #fff;
  background-color: #2e170f;
  border-color: var(--brand-color);
  text-decoration: none;
}
.theme-btn:not(.success-btn):disabled,
.theme-btn.disabled{
  background-color: #2e170f !important;
}

.theme-btn:active {
  color: #fff !important;
  background-color: #2e170f !important;
  box-shadow: 0 0 0 .2rem #42211636;
  border-color: var(--brand-color) !important
}

.custom-checkbox .custom-control-label::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid var(--brand-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.custom-checkbox .custom-control-input:checked~::after {
  display: block;
}

input:-internal-autofill-selected {}

.otp-controls {
  display: flex;
  justify-content: center;
}

.otp-controls input {
  border-radius: 0px;
  border-left: 0px;
  text-align: center;
  min-height: 50px;
  font-size: 18px;
  width: 100% !important;
}

.otp-controls input:first-child {
  border-left: 1px solid;
  border-radius: 5px 0px 0px 5px;
}

.otp-controls input:last-child {
  border-radius: 0px 5px 5px 0px;
}

.otp-control-group {
  max-width: 350px;
  margin: auto;
}

.btn:hover {
  text-decoration: none;
}

.full-height {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.sidebar {
  flex-wrap: wrap;
  width: 250px;
  position: fixed;
  background-color: var(--brand-bg);
  top: 0px;
  bottom: 0px;
  padding: 0px;
  align-items: flex-start;
  z-index: 1;
}

.sidebar .navbar-brand {
  padding: 0px;
  margin: 0px;
  display: block;
  width: 100%;
  margin: 20px 0px -40px;
}

.sidebar .navbar-brand img {
  max-width: 200px;
  display: block;
  margin: auto;
}

.sidebar .navbar-nav {
  width: 100%;
}

.sidebar .navbar-nav a {
  padding: 10px 15px;
  position: relative;
}

.sidebar .navbar-nav a i {
  margin-right: 15px;
}

.sidebar .navbar-nav a {
  color: #000000;
  text-decoration: none;
}

.sidebar .navbar-nav a:hover {
  background-color: #FAF8F5;
}

.sidebar .navbar-nav a::before {
  content: "";
  left: 0px;
  width: 3px;
  height: 100%;
  transform: scaleY(.9);
  height: 0px;
  background-color: var(--brand-color);
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  border-radius: 20px;
  transition: all .2s ease-in-out;
  transform-origin: center;
}

.sidebar .navbar-nav a:hover:before {
  height: 100%;
  transform: scaleY(.7);
}

.top-nav .user-profile {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}

.top-nav .user-profile:hover::before {
  display: none;

}

.top-nav .user-profile img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.top-nav .user-profile>div {
  margin-left: 10px;
}

.sidebar .user-profile>div span {
  display: block;
  line-height: 1.2;
}

.sidebar .user-profile>div span:last-child {
  font-size: 14px;
}

.top-nav {
  margin-left: 250px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: fixed;
  right: 0px;
  left: 0px;
  top: 0px;
  box-shadow: 0px 2px 4px #00000014;
  background-color: #f9f9f9 !important;
  z-index: 9;
}

.top-nav .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.page-title {
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 0px;
}

.page-title:hover,
.right-navbar a:hover,
.page-title:focus,
.right-navbar a:focus {
  text-decoration: none;
}

.right-navbar a {
  font-size: 18px;
}

.right-navbar input {
  padding: 0px;
  height: auto;
  min-height: 35px;
  line-height: 1;
  padding-left: 35px;
  border-radius: 40px;
  border-color: #272D3B;
}

.search-form {
  position: relative;
  width: 200px;
}

.btn.search-btn {
  padding: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-navbar .nav-item {
  margin: 0px 2px;
}

.right-navbar .nav-item:last-child {
  margin-right: 0px;
}

main.main-content {
  position: relative;
  margin-left: 250px;
  margin-top: 62px;
  min-height: calc(100vh - 72px);
  background: #F9F9F9;
}

.table-header-shadow thead {
  background-color: #EFEFEF;
  box-shadow: 0px 2px 5px #00000010;
  border-bottom: 1px solid #cfcfcf !important;
}

.table-header-shadow th {
  background-color: #EFEFEF;
}

table .custom-checkbox .custom-control-label {
  top: -15px;
}

.search-input {
  position: relative;
}

.search-input .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.theme-btn-sm {
  padding: 5px 15px;
  font-weight: normal;
}

.btn-inverse {
  background-color: var(--brand-bg);
  color: #000000;
  border: 1px solid var(--brand-color);
}

.cell-1-small th:first-child,
.cell-1-small td:first-child {
  width: 50px;
  max-width: 40px;
}

.action-btns a {
  margin: 0px 5px;
  width: calc(100% - 10px);
}

.table-header-shadow th,
.table-header-shadow td {
  border: none;
  border-bottom: 1px solid #3e221542;
  padding: 10px 10px;
}

.table-header-shadow th:first-child,
.table-header-shadow td:first-child {
  border-left: 1px solid #3e221542;
}

.table-header-shadow th {
  border-bottom: 1px solid #3e221542;
  border-top: 1px solid #3e221542;
}

.table-responsive {
  box-shadow: 0px 0px 9px #00000012;
}

.custom-card.card {
  border-radius: 0px;
  box-shadow: 0px 3px 5px #00000016;
  border: none;
}

.pagination select {
  padding: 4px;
  -webkit-appearance: auto;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.pagination li a {
  padding: 0px;
  width: auto;
  height: auto;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: var(--brand-color);
  padding: 5px 10px;
  border-radius: 6px !important;
  line-height: 20px;
  min-height: 30px;
}

.pagination {
  padding: 5px;
  align-items: center;
}

.pagination li a:hover,
.pagination li a:focus,
.pagination .active a {
  background-color: var(--brand-color) !important;
  color: #fff;
  text-decoration: none;
  box-shadow: none;
}

.pagination li a i {
  font-size: 10px;
}

.form-switch .form-check-input {
  width: 40px;
  height: 20px;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
  background-color: #A2A2A2;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #0060C1;
  border-color: #0060C1;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}

.form-check-input:focus {
  border-color: #0061c17e;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0061c149;
}

.font-12 {
  font-size: 12px;
}

.table-responsive {
  min-height: calc(100vh - 200px);
}

table .has-checkbox .custom-control-label {
  top: 0px !important;
}

table .has-checkbox {
  border-radius: 0px;
  border: 0px;
  box-shadow: -3px 0px 11px #0000001f;
  top: 32px;
  right: -9px !important;
  max-height: 200px;
  overflow: auto;
}

table .has-checkbox .dropdown-item:active {
  background-color: #f8f9fa;
  color: #212529;
}

table .custom-checkbox .custom-control-input:checked~::after {
  border-color: #fff;
}

table .custom-checkbox .custom-control-input:checked~ ::before {
  background-color: var(--brand-color);
}

table .has-checkbox::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

table .has-checkbox::-webkit-scrollbar-thumb {
  background-color: #CACCCB;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #CACCCB;
}

table tr:hover td {
  background-color: #F4F1EA;
}

.action-btns a:hover,
.action-btns a:focus {
  text-decoration: none;
}

.action-btns a .icon {
  font-size: 15px;
}

.action-btns a .icon-view {
  font-size: 17px;
  line-height: 24px;
}

.modal .form-control {
  background-color: #F9F9F9;
  border-color: transparent;
}

.modal .form-control:focus {
  box-shadow: none;
  background-color: #F4F1EA;
  border-color: #3E2215;
}

select {
  appearance: auto !important;
}

.modal select {
  min-height: 38px;
}

.modal-header,
.modal-body {
  padding: 10px 20px;
}

.modal-title {
  font-size: 20px;
  font-family: var(--body-fonts);
  font-weight: 600;
}

.modal-header {
  background-color: var(--brand-color);
  color: #fff;
}

.modal-header .btn-close {
  --bs-btn-close-bg: url(data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>);
  opacity: 1;
}

.modal-header .btn-close:focus {
  outline: 0;
  box-shadow: none;
}

.btn-light {
  background-color: #F9F9FB;
  border: 1px solid #D6D4D4;
}

.no-btn.dropdown-toggle {
  padding: 0px;
  background: none;
  border: none;
}

.no-btn.dropdown-toggle:hover,
.no-btn.dropdown-toggle:focus,
.no-btn.dropdown-toggle:active {
  background: none;
  border: none;
  box-shadow: none;
}

.no-btn.dropdown-toggle::after {
  color: #3E2215;
}

.sidebar-collapse+div .nav-link {
  padding: 5px;
}

.sidebar-collapse+div .nav-link:hover {
  background: none;
  color: #773c28;
  font-weight: 600;
}

.sidebar-collapse+div .nav-link.active {
  background: none;
  color: #773c28;
  font-weight: 600;
}

.sidebar-collapse+div {
  padding-left: 40px;
}

.sidebar-collapse+div .nav-link::before {
  display: none;
}

.sidebar .navbar {
  margin-top: 75px;
  height: calc(100vh - 130px);
  overflow: auto;
  align-items: flex-start;
}


.sidebar .navbar::-webkit-scrollbar {
  width: 5px;
  background-color: #e5e0d6;
  border-radius: 10px;
}

.sidebar .navbar::-webkit-scrollbar-thumb {
  background-color: var(--brand-color);
  border-radius: 10px;

}


.search-dropdown .no-btn.dropdown-toggle::after {
  display: none;
}

.search-dropdown button:hover,
.search-dropdown button:focus,
.search-dropdown button:active {
  background: none;
  color: inherit;
  border: none;
  box-shadow: none;
}

.search-dropdown .dropdown-menu {
  width: 100%;
  border-radius: 0px;
  border: 0px;
  box-shadow: -3px 0px 11px #0000001f;
  top: 32px;
  right: -9px !important;
  max-height: 200px;
  overflow: auto;
  padding: 2px;
}

.search-dropdown .dropdown-menu a {
  padding: 2px 10px;
}

.search-dropdown .dropdown-menu a:hover,
.search-dropdown .dropdown-menu a:focus {
  background-color: #f8f9fa;
  color: #212529;
}

.text-error {
  color: #ff0000 !important;
  font-size: 12px;
}

.light-modal {
  background-color: #F4F1EA;
}

.inventory-detail-modal label {
  font-size: 14px;
  min-width: 120px;
  font-family: var(--body-fonts);
  font-weight: 600;
}

.form-control {
  border-radius: 5px;
}

.inventory-detail-modal .form-control {
  background-color: #fff;
  font-size: 14px;
}

.inventory-detail-modal .form-control:focus {
  background-color: #fff;
}

.table-responsive .table-row.collapse {
  display: none;
}

.table-responsive .table-row.collapse.is-open {
  display: table-row;
}

.collapsible-toggle svg,
.collapsible-toggle i,
.table-row.collapse:hover,
.collapsible-toggle {
  cursor: pointer;
}

.collapsible-toggle.inner-collapse-toggle {
  padding-left: 20px;
}

.collapsible-toggle a {
  font-size: 8px;
  margin-right: 20px;
}

.collapsible-toggle a:hover,
.collapsible-toggle a:active,
.collapsible-toggle a:focus,
.collapsible-toggle a:visited {
  text-decoration: none;
}

.table-row.collapse:hover {
  text-decoration: none;
}

.table-row.collapse td {
  background: #F9F9F9;
}

.table-row.collapse:hover td {
  background-color: #D2D1D1;
}

.br-1 {
  border-right: 1px solid #efefef;
}

.top-card .p-3 {
  padding: 10px !important;
}

.top-card {
  font-size: 16px;
  font-weight: 600;
}

.table-card .card-header {
  background-color: #fff;
  border: none;
}

.table-card .card-header .card-title {
  font-size: 18px;
  font-weight: 600;
}

.table-card table tr td {
  border: none !important;
  padding: 15px 10px;
}

.table-card table tr th {
  background-color: var(--brand-bg);
  border: none !important;
  border-right: 1px solid #3e22151f !important;
}

.table-card table tr th:last-child {
  border: none !important;
}

.table-card table tr:hover td {
  background-color: #F9F9F9;
}

.box-shadow-0 {
  box-shadow: none;
}

.table-card .tbl-pad-normal table tr td {
  padding: 5px 10px !important;
  border-bottom: 1px solid #3e22151f !important;
}

.table-responsive.height-auto {
  height: auto;
  min-height: initial;
}

table .proccess-status {
  padding: 0px !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #D2D1D1;
  font-size: 12px;
  line-height: 30px;
  position: relative;
}

table .proccess-status.is-active {
  border-color: var(--brand-color);
}

table .proccess-status.done::before {
  display: none;
}

table .proccess-status:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 45px;
  background: #D2D1D1;
  top: 23px;
  border-radius: 15px;
}

table .proccess-status.is-active::before {
  background-color: #F3E5C3;
}

.bg-none {
  background-color: transparent !important;
}

.custom-checkbox .custom-control-input.disabled~ :before {
  background-color: #D2D1D1 !important;
  border-color: transparent;
}

.table-responsive.tbl-pad-normal {
  min-height: 255px;
}

.version-details .table-responsive {
  height: auto;
}

.version-details .table-responsive th,
.version-details .table-responsive td {
  padding: 5px 10px !important;
  border-bottom: 1px solid #3e22151f !important;
}

.profile-page h5 {
  font-family: var(--body-fonts);
}

.profile-page .card:not(.border-0) {
  border-radius: 5px !important;
  border: 1px solid #e7e7e7 !important;
}

.profile-page .img {
  position: relative;
}

.profile-page .change-photo {
  position: absolute;
  font-size: 20px;
  background: #fff;
  border-radius: 100%;
  right: 0;
  top: 5px;
}

.profile-page .img img {
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

.profile-page h6 {
  font-family: var(--body-fonts);
  font-size: 18px;
  font-weight: 600;
}

.profile-page .btn-default {
  background-color: #fff;
  border: 1px solid #e7e7e7 !important;
  border-radius: 5px;
}

.profile-page .btn-default i {
  margin-left: 10px;
}

.profile-page .text-muted {
  color: #7A7A7A !important;
}

.profile-page p {
  color: #000;
}

.password-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 12px;
  height: 42px;
}

.password-btn i {
  position: static;
  padding: 0px;
  border: none;
}

.sticky-action {
  padding: 10px 15px;
}

.sticky-action i {
  cursor: pointer;
}

.sticky-action .icon-delete {
  margin-left: 10px;
}

.sticky-action .icon-close {
  margin-left: 10px;
  font-size: 14px;
}

.card.has-input .form-control:disabled {
  border: none;
  padding: 0px;
  background: none;
  margin-bottom: 1rem;
  color: #000;
}

.card.has-input .form-control::placeholder {
  color: #000;
}

.card.has-input label {
  color: #7A7A7A !important;
}

.password-btn .icon-eye-slash-fill {
  font-size: 14px;
}

.password-box input {
  padding-right: 35px;
}

.status {
  position: relative;
  padding-left: 15px;
}

.status::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  top: 7px;
}

.status.text-success {
  color: #1C9E0D;
}

.status.text-success::before {
  background-color: #1C9E0D;
}

.status.text-danger {
  color: #FC0A0A;
}

.status.text-danger::before {
  background-color: #FC0A0A;
}

.status.text-warning {
  color: #ffbb00;
}

.status.text-warning::before {
  background-color: #ffbb00;
}

.status.text-info {
  color: #0D6EFD !important;
}

.status.text-info::before {
  background-color: #0D6EFD !important;
}

.status.text-pending,
.text-pending {
  color: #5c5c5c;
}

.status.text-pending::before {
  background-color: #5c5c5c;
}



.chevron-circle {
  width: 20px;
  height: 20px;
  background-color: var(--brand-color);
  color: #fff;
  font-size: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.caret-hide::after {
  display: none;
}

.custom-dropdown .dropdown-menu {
  border-radius: 0px;
  border: 0px;
  box-shadow: -3px 0px 11px #0000001f;
}

.custom-dropdown .dropdown-menu i {
  margin-right: 5px;
  width: 15px;
  display: inline-block;
}

.custom-dropdown .dropdown-menu a:hover,
.custom-dropdown .dropdown-menu a:active,
.custom-dropdown .dropdown-menu a:focus {
  text-decoration: none;
  background-color: #F1EBDC;
  color: var(--brand-color);
}

.populate-bar {
  position: fixed;
  width: 350px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0px 10px 23px #00000016;
}

.populate-bar .populate-bar-header {
  padding: 10px;
  border-bottom: 1px solid #3e221517;
}

.populate-bar .populate-bar-body {
  padding: 10px 15px 30px;
  max-height: calc(100vh - 40px);
  overflow: auto;
}

.populate-bar .populate-bar-header p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
}

.populate-bar .populate-bar-header i {
  margin-right: 10px;
  font-size: 14px;
}

.item-card {
  background-color: #FDFDFD;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 3px 7px #00000016;
  margin-bottom: 20px;
}

.item-card .item-header {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F4F1EB;
  border-radius: 5px;
}

.item-card .item-header span:first-child {
  font-size: 18px;
  font-weight: 600;
}

.item-card .item-body {
  padding: 10px;
}

.slug-custom-dropdown .custom-dropdown-menu .item-body {
  padding: 10px;
}

.slug-custom-dropdown .custom-dropdown-menu .item-card {
  background-color: #FDFDFD;
  border-radius: 5px;
  box-shadow: 0px 3px 7px #00000016;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  border: 1px solid #3e22151a;
  margin-top: 10px;
}

.slug-custom-dropdown .custom-dropdown-menu {
  width: max-content;
}

.populate-values {
  display: flex;
  flex-wrap: wrap;
}

.populate-values .value {
  margin-right: 5px;
  font-size: 12px;
  margin-bottom: 10px;
}

.populate-values .value label {
  display: block;
  margin-bottom: 5px;
}

.populate-values .value .label-box {
  color: #3E2215;
  padding: 5px;
  border: 1px solid #3E2215;
  border-radius: 5px;
  font-family: 'Twentieth Century';
  font-weight: 600;
}


.populate-bar-body .custom-checkbox .custom-control-input:checked~::after {
  border-color: #fff;
}

.populate-bar-body .custom-checkbox .custom-control-input:checked~ ::before {
  background-color: var(--brand-color);
}

.card.has-radius {
  border-radius: 5px;
}

.card.has-radius .section-title {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--body-fonts);
}

.date-label {
  min-width: 100px;
  margin-bottom: 0px;
}

.slug-custom-dropdown .toggle,
.tracking-status .item-title {
  background-color: #F4F1EA;
  padding: 6px 10px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.slug-custom-dropdown .toggle i {
  font-size: 8px;
}

.slug-custom-dropdown .toggle:hover {
  background-color: var(--brand-color);
  color: #fff;
}

.card.has-input label.text-black {
  color: #000 !important;

}

.text-black {
  color: #000 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.track-stepper ul {
  display: flex;
  position: relative;
  margin-top: 20px;
  justify-content: center;
}

.track-stepper ul li::after {
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1px;
  background-color: #707070;
}

.track-stepper ul li {
  position: relative;
  width: calc(100% / 4);
}

.w-max-content {
  width: max-content;
}

.track-stepper ul li:before {
  content: "";
  position: absolute;
  left: 80px;
  right: 0px;
  background: #707070;
  height: 1px;
  top: 15px;
}

.track-stepper ul li:first-child::before {
  left: 70px;
}

.track-stepper ul li .w-100 {
  margin-top: 5px;
  font-weight: 600;
  color: #000;
}

.track-stepper ul li:last-child::before {
  display: none;
}

.primary-bg {
  background-color: var(--brand-color) !important;

}

.assembally-attr label {
  padding: 8px 12px;
  background-color: #F5F2EB;
  min-width: 120px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.print-view-modal .form-control {
  background-color: #fff;
}

.print-view-modal .form-control:focus {
  background-color: #fff;
}

.print-view-modal .d-flex>label {
  min-width: 100px;
}

.print-view-modal .modal-content {
  background-color: #F4F1EA;
}

.print-view-modal .modal-header,
.print-view-modal .modal-footer {
  display: none;
}

.print-view-modal .item-card .item-header {
  background-color: #EBEAEA;
}

.print-view-modal .value .label-box {
  padding: 8px;
  display: block;
}

.print-view-modal .populate-values .value {
  display: block;
  margin-bottom: 5px;
}

.print-view-modal .inner {
  padding: 10px 30px 30px;
}

.print-view-modal .modal-body {
  padding: 30px 20px 20px;
}

.print-view-modal h3 {
  font-size: 25px;
}

.populate-bar-body .item-header i {
  color: var(--brand-color);
  cursor: pointer;
}

.track-status {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #3E2215;
  font-size: 13px;
  font-weight: 600;
  background-color: #fff;
}

.track-status.done {
  color: #fff;
  background-color: #009C21;
  border-color: transparent;
}

.track-status.in-progress {
  background-color: #D81111;
  border-color: transparent;
  color: #fff;
}

.track-stepper .track-status.done,
.track-stepper .track-status.in-progress {
  background-color: var(--brand-color);
}

.track-stepper .track-status.done {
  background-color: #009C21;
}

.track-stepper .track-status.in-progress {
  background-color: #D81111;
}

.cursor-pointer {
  cursor: pointer;
}

.report-modal .slug-custom-dropdown .toggle {
  background-color: #fff;
  box-shadow: 0px 3px 6px #0000004a;
}

.report-modal .slug-custom-dropdown .toggle:hover {
  background-color: var(--brand-color);
  color: #fff;
}

.report-modal .track-status {
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.report-modal .track-stepper ul li .w-100 {
  font-size: 12px;
}

.report-modal .card {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border-color: #cbcbcb;
  margin-top: 20px;
}

.report-modal label {
  line-height: 1;
  color: #000;
  font-weight: bold;
  margin-bottom: 0px;
}

.report-modal .text-muted {
  color: #7A7A7A !important;
}

.report-modal+.modal-footer {
  display: none !important;
}

.report-modal {
  padding-bottom: 30px;
}

.populate-bar {
  right: -100%;
  transition: all .2s ease-in-out;
}

.populate-bar.show {
  right: 0px;
}

.role-table th:not(:nth-child(2)),
.role-table td:not(:nth-child(2)) {
  text-align: center;
}

.role-table th:first-child {
  width: 50px;
  max-width: 30px;
}

.role-table {
  min-height: calc(100vh - 230px);
}

.value-field div {
  background-color: #F4F1EA;
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid #3e22154a;
}

.success-btn,
.success-btn:focus,
.success-btn:active {
  background-color: #009C21 !important;
  border: 1px solid #009C21 !important;
  color: #fff !important;
}

.success-btn:hover {
  background-color: #007c1b !important;
}

.success-btn:active {
  box-shadow: 0 0 0 .2rem #009c223d;
}

.info-btn,
.info-btn:disabled,
.info-btn:focus,
.info-btn:active {
  background-color: #0D6EFD !important;
  border: 1px solid #0D6EFD !important;
  color: #fff;
}

.info-btn:hover {
  background-color: #0a62e6 !important;
}

.info-btn:active {
  box-shadow: 0 0 0 .2rem #00449c3d;
}

.font-18 {
  font-size: 18px;
}

.font-11 {
  font-size: 11px;
}

.font-10 {
  font-size: 10px;
}

.text-capitalize {
  text-transform: capitalize;
}

.no-data-found {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

table th,
table td {
  white-space: nowrap;
}

table button:not(.btn-primary) .dropdown-menu {
  position: fixed !important;
  z-index: 99;
  transform: translate(0) !important;
  top: 170px !important;
  right: 10px !important;
}

.table-top-actions i.icon-add {
  font-size: 12px;
  margin-right: 5px;
}

.main-loader {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 280px);
}

.main-loader img {
  max-width: 75px;
  position: relative;
  animation: loader 1.5s infinite linear;
  transform: rotateX(0deg);
}

@keyframes loader {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(180deg)
  }

  75% {
    transform: rotateY(-180deg);
  }

  100% {
    transform: rotateY(0deg)
  }
}


.react-datepicker-wrapper {
  display: block !important;
  width: 100%;
}

.cell-dropdown .dropdown-menu {
  position: fixed !important;
  right: 10px !important;
  top: 10px !important;
  left: auto !important;
}

.table-header-shadow th:last-child {
  position: sticky;
  z-index: 1;
  right: 0px;
}


@media print {
  .print-page {
    background-color: var(--brand-bg);
    min-height: 100vh;
    padding-top: 20px;
  }

  .pritable-area {
    margin: 20px 20px 20px;
    padding: 10px 30px 30px;
    margin: 0px;
  }

  .pritable-area .form-control {
    background-color: #fff;
  }

  .pritable-area .col-md-4 {
    flex: 0 0 33.33%;
    width: 33.33333333%;
  }

  .pritable-area .d-flex>label {
    min-width: 100px;
  }

  .pritable-area .inner {
    padding: 10px 0px 30px;
  }

  .pritable-area .col-md-2 {
    flex: 0 0 16.66666667%;
    width: 16.66666667%;
  }

  .pritable-area .col-md-10 {
    flex: 0 0 83.33333333%;
    width: 83.33333333%;
  }
}

.filter-btn {
  padding: 0px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-btn i {
  margin: 0px !important;
}

.populate-bar:before {
  content: "";
  position: fixed;
  left: 0px;
  right: 0;
  background: #000;
  top: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all .2s ease-in-out;
  pointer-events: none;
}

.populate-bar.show:before {
  opacity: .5;
}

.filter-bar {
  padding: 20px;
}

.filter-bar .filter-header h5 {
  margin-bottom: 0px;
  position: relative;
  padding-left: 15px;
}

.filter-bar .filter-header {
  position: relative;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}


.filter-bar .close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: auto;
  color: #555;
  font-size: 12px;
  cursor: pointer;
}

.filter-bar .filter-header::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0;
  width: 7px;
  background: #e9e2d3;
  top: auto;
  bottom: auto;
  transition: all .2s ease-in-out;
  pointer-events: none;
  height: calc(100% - 20px);
  border-radius: 2px;
}

.filter-bar .filter-header::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1px;
  bottom: 0px;
  width: 100%;
  background-color: #ddd;
}

.filter-bar .filter-body {
  padding: 20px 0px;
}

.filter-bar .close-btn:hover {
  background-color: #e3e3e3;
}

.dash-card {
  background-color: #fff;
  padding: 30px;
}

.dash-card p.title {
  font-size: 23px;
  margin-bottom: 5px;
  color: var(--brand-color);
}

.dash-card i {
  font-size: 100px;
  position: absolute;
  right: 20px;
  bottom: 0px;
  opacity: .1;
  color: #3E2215;
  display: flex;
  align-items: center;
  max-width: 120px;
  width: 120px;
  justify-content: flex-end;
  bottom: 10px;
}

.dash-card {
  background-image: linear-gradient(45deg, #2c007c, #5200d1);
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  color: #0E0E10;
  background: #f4f2ebb3 !important;
  border: 1px solid #3d211533;
}

.col-md-4:nth-child(2) .dash-card {
  background-image: linear-gradient(45deg, #dd7a00, #ffca00)
}

.col-md-4:nth-child(3) .dash-card {
  background-image: linear-gradient(45deg, #27a527, #65e53e);
}

.col-md-4:nth-child(4) .dash-card {
  background-image: linear-gradient(45deg, #ad0000, #ff2e98);
}

.col-md-4:nth-child(5) .dash-card {
  background-image: linear-gradient(45deg, #9400d1, #c842ff);
}

.dash-card p.count {
  color: var(--brand-color);
  font-size: 28px;
  line-height: 1;
  font-weight: bold;
}

.no-btn.dropdown-toggle.show .icon-chevron-down {
  transform: rotate(180deg);
}

.slug-custom-dropdown.open .icon-caret-up {
  transform: rotate(180deg) translate(0px, 3px);
  display: inline-block;
}

.slug-custom-dropdown.open .icon-caret-up:before {
  content: "\e924";
}



/*-------Button Loader css-------*/

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 18px;
}
.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}




.custom-disabled *,
.custom-disabled{
  cursor: not-allowed;
}
.pagination li.custom-disabled a:hover,
.pagination li.custom-disabled a:active,
.pagination li.custom-disabled a:focus{
  color: var(--brand-color) !important;
  background-color: transparent !important;
}
.populate-bar-wrapper{
  width: 350px    !important;
}
.populate-bar{
  right: 0px;
  transition: none !important;
}
.stock-table{
  background-color: #fff;
}
.stock-table td,
.stock-table th{
  text-align: center;
  border: 1px solid #F4F1EA;
}
.stock-table th,
.stock-table td{
  padding: 10px;
}
.stock-table tr:hover td{
  background-color: #fff;
}
.page-showing{
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #3c231656;
}
.populate-header{
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: var(--brand-color);
  color: var(--brand-bg) !important;
}
.populate-header i{
  font-size: 8px;
}
.populate-item-wrapper{
  display: none;
  padding: 10px;
}
.populate-item-wrapper.show{
  display: block;
}
.populate-main-item{
border: 1px solid #ddd;
margin-bottom: 10px;
}
.collapsible-card .card-header{
  border-bottom: 0px;
  color: var(--brand-bg);
  background-color: var(--brand-color);
  cursor: pointer;
}
.collapsible-card{
  overflow: hidden;
}
.collapsible-card .chevron-circle{
  font-size: 7px;
  width: auto;
  height: auto;
}
.collapsible-card > .card-body.show{
  display: block;
}
.collapsible-card > .card-body{
  display: none;
}
.item-card .counts{
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--brand-color);
  color: var(--brand-bg);
  display: inline-block;
  text-align: center;
  line-height: 20px;
  margin-left: 5px;
}
.back-arrow{
  font-size: 16px;
  margin-right: 10px; 
}
.cursor-pointer{
  cursor: pointer;
}
.active-panel{
  background-color: var(--brand-color) !important;
  color: #fff;
}
.report-items .track-stepper ul{
  justify-content: flex-start;
}
.report-items .assembally-attr{
  display: none;
}
.report-items .assembally-attr-visible{
  display: flex;
}
.min-height-auto{
  min-height: auto !important;
}
.dash-card-main{
  border-radius: 0px;
}
.dash-card-main .card-header{
  background-color: var(--brand-bg);
}
.dash-card-main .card-header,
.dash-card-main .card-body{
  padding: 15px;
}
.dash-card-main .card-header .card-title{
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
}
.dash-card-main > .card-body{
  padding-bottom: 0px;
}




/* ribbon effect   */
.has-ribbon-text .ribbon {
  --f: 5px;
  position: absolute;
  top: 0;
  left: 4px;
  line-height: 1.2;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(100% calc(100% - var(--f)), 100% 100%, calc(100% - var(--f)) calc(100% - var(--f)), var(--f) calc(100% - var(--f)), 0 100%, 0 calc(100% - var(--f)), 999px calc(100% - var(--f) - 999px), calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((cos(45deg) - 1)* 100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: #ff8d00;
}
.has-ribbon-text .ribbon {
  font-size: 14px;
  color: #fff;
}
.has-ribbon-text td{
  position: relative;
}
.has-ribbon-text td .ribbon + div{
  padding-left: 35px;
}
.ribbon-label {
  padding: 3px 10px;
  font-size: 16px;
  border-radius: 40px;
  box-shadow: rgba(255, 255, 255, 0.1) -3px -3px 9px inset, rgba(255, 255, 255, 0.15) 0 3px 9px inset, rgba(255, 255, 255, 0.6) 0 1px 1px inset, rgba(0, 0, 0, 0.3) 0 -8px 36px inset, rgba(255, 255, 255, 0.6) 0 1px 5px inset, rgba(0, 0, 0, 0.2) 2px 19px 31px;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, .23) 0, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, .55) 0, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  background-color: #ff8d00;
  color: #fff;
}
.sidebar-btn{
  display: none;
  position: fixed;
    right: 103px;
    top: 15px;
    z-index: 99;
}
.sidebar-btn div{
    width: 20px;
    height: 2px;
    background-color: rgba(0, 0, 0, .65);
    margin: 6px 0px;
}
.sidebar.isShow{
  left: 0px;
}