
@media (max-width:767px) {
  .sidebar-btn{
    display: block;
    
  }
  .sidebar {
    width: 210px;
    z-index: 99;
  }
  .top-nav,
  main.main-content {
    margin-left: 0px;
  }

  .top-nav .navbar-nav {
    flex-direction: row;
  }

  .top-nav .user-profile>div {
    display: none;
  }

  .search-form {
    display: none !important;
  }

  .right-navbar a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-m-0{
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-m-0 .col-12{
    padding: 0px;
  }
  .table-top-actions .col-md-4,
  .table-top-actions .search-input{
    margin-bottom: 10px !important;
  }
  .table-header-shadow th, .table-header-shadow td{
    white-space: nowrap;
  }
  .sidebar{
    left: -100%;
  }
  .login-wrapper{
    padding: 0px 15px;
  }
}

@media (max-width:1366px) {
  .dash-card i {
    font-size: 60px;
    bottom: 30px;
  }

  .dash-card p.title {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .dash-card p.count {
    font-size: 25px;
  }
}