@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?gq4bt1');
  src:  url('fonts/icomoon.eot?gq4bt1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?gq4bt1') format('truetype'),
    url('fonts/icomoon.woff?gq4bt1') format('woff'),
    url('fonts/icomoon.svg?gq4bt1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-approval-pending:before {
  content: "\e92b";
}
.icon-back-office:before {
  content: "\e92c";
}
.icon-cashier:before {
  content: "\e92d";
}
.icon-confirmation-in-progress:before {
  content: "\e92e";
}
.icon-store-coordinator:before {
  content: "\e92f";
}
.icon-store-conrdinators:before {
  content: "\e91f";
}
.icon-total-orders:before {
  content: "\e926";
}
.icon-cancelled-order:before {
  content: "\e927";
}
.icon-confirmed-orders:before {
  content: "\e928";
}
.icon-orders:before {
  content: "\e929";
}
.icon-pending-orders:before {
  content: "\e92a";
}
.icon-dashboard1:before {
  content: "\e91d";
}
.icon-file-pdf:before {
  content: "\e91e";
}
.icon-populate:before {
  content: "\e920";
}
.icon-printer:before {
  content: "\e921";
}
.icon-printer-filled:before {
  content: "\e922";
}
.icon-refresh:before {
  content: "\e923";
}
.icon-caret-down:before {
  content: "\e924";
}
.icon-check:before {
  content: "\e925";
}
.icon-close:before {
  content: "\e91c";
}
.icon-eye-fill:before {
  content: "\e919";
}
.icon-eye-slash-fill:before {
  content: "\e91a";
}
.icon-delete:before {
  content: "\e91b";
}
.icon-pencil-circle:before {
  content: "\e917";
}
.icon-edit1:before {
  content: "\e918";
}
.icon-chevron-right:before {
  content: "\e915";
}
.icon-chevron-left:before {
  content: "\e916";
}
.icon-chevron-down:before {
  content: "\e913";
}
.icon-chevron-up:before {
  content: "\e914";
}
.icon-add:before {
  content: "\e912";
}
.icon-notification:before {
  content: "\e910";
}
.icon-logout:before {
  content: "\e911";
}
.icon-inventory:before {
  content: "\e900";
}
.icon-key:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e902";
}
.icon-oreders:before {
  content: "\e903";
}
.icon-product:before {
  content: "\e904";
}
.icon-reports:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-store:before {
  content: "\e907";
}
.icon-trash:before {
  content: "\e908";
}
.icon-users:before {
  content: "\e909";
}
.icon-view:before {
  content: "\e90a";
}
.icon-brand:before {
  content: "\e90b";
}
.icon-customers:before {
  content: "\e90c";
}
.icon-dashboard:before {
  content: "\e90d";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-filter:before {
  content: "\e90f";
}
